import { yupResolver } from '@hookform/resolvers/yup';
import { useCurrentUserQuery } from '@pesto/hubble-shared/__generated__/graphql/api';
import { ButtonContainer } from '@pesto/hubble-shared/components/ButtonContainer/ButtonContainer';
import Button from '@pesto/hubble-shared/components/UI/Button/Button';
import { SSN } from '@pesto/hubble-shared/components/UI/Form/SSN/SSN';
import { TextField } from '@pesto/hubble-shared/components/UI/Form/TextField/TextField';
import { Typography } from '@pesto/hubble-shared/components/UI/Typography';
import { formatToCurrency } from '@pesto/hubble-shared/utils/formatterHelper';
import { validateSSN } from '@pesto/hubble-shared/utils/ssnHelper';
import { useEffect } from 'react';
import { type Resolver, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { dob } from '../../../../../utils/sharedFieldValidations';
import { splitDOB } from '../../../../../utils/splitDOB';
import { MONEY_MULTIPLIER } from '../../../../constants/application';

import { FormattedAddress } from './FormattedAddress';

interface Values {
  ssnOrTid: string;
  ssn1: string;
  ssn2: string;
  income: number;
  dob: string;
  deviceSessionId: string;
  isEditableSSN: boolean;
  isEditableDob: boolean;
}

interface PrefilledPersonalDataProps {
  editableSSN?: boolean;
  onSubmit?: (value: string) => void;
}

const PrefilledPersonalData = ({ editableSSN = false, onSubmit }: PrefilledPersonalDataProps) => {
  const { data } = useCurrentUserQuery();
  const user = data?.currentUser;

  const prefilledValidationSchema = yup.object().shape({
    ssn1: yup.string().when('isEditableSSN', {
      is: true,
      then: schema =>
        schema
          .required('SSN/ITIN is required')
          .test('ssn-length', 'SSN/ITIN must be 9 digits long', function (value) {
            return value ? value.length >= 9 : true;
          })
          .test('ssn', 'SSN or ITIN is invalid or length of the code is not enough', function (value) {
            return value ? validateSSN(value) : true;
          }),
      otherwise: schema => schema.notRequired(),
    }),
    ssn2: yup
      .string()
      .required('SSN/ITIN is required')
      .test('ssn-length', 'SSN/ITIN must be 9 digits long', function (value) {
        return value ? value.length >= 9 : true;
      })
      .test('ssn', 'SSN or ITIN is invalid or length of the code is not enough', function (value) {
        return value ? validateSSN(value) : true;
      })
      .when('isEditableSSN', {
        is: true,
        then: schema =>
          schema.test('ssn-match', 'SSN or ITIN does not match', function (value) {
            return value === this.resolve(yup.ref('ssn1'));
          }),
      }),
    dob: yup.string().when('isEditableDob', {
      is: true,
      then: () => dob,
    }),
  });

  const prefilledValues = {
    annualIncome: user?.annualIncome,
    ssn: user?.ssnLastFour,
    dob: user?.dob,
  };
  const isEditableDob = !prefilledValues.dob;

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitted },
    trigger,
  } = useForm<Values>({
    mode: 'onChange',
    resolver: yupResolver(prefilledValidationSchema) as unknown as Resolver<Values>,
    defaultValues: {
      dob: '',
      ssn1: '',
      ssn2: '',
      isEditableSSN: editableSSN,
      isEditableDob: isEditableDob,
    },
  });

  const formValues = {
    ssn1: watch('ssn1'),
    ssn2: watch('ssn2'),
    dob: watch('dob'),
  };

  const [year, month, day] = splitDOB(formValues.dob || prefilledValues.dob || '');

  useEffect(() => {
    if (isSubmitted) {
      trigger();
    }
  }, [isSubmitted, formValues.ssn2, trigger]);

  const onFormSubmit = (data: Values) => {
    onSubmit?.(data.ssn2);
  };
  const incomeFormated = formatToCurrency(parseInt(prefilledValues.annualIncome || '0') / MONEY_MULTIPLIER);
  const dobFormatted = `${month}/${day}/${year}`;

  return (
    <div className="flex w-full max-w-[500px] flex-col items-center gap-4 text-left">
      <FormattedAddress billingAddress={user?.billingAddress} />
      <div className="flex w-full max-w-[500px] flex-col items-center gap-4 text-left">
        <form className="mt-2 w-full" onSubmit={handleSubmit(onFormSubmit)}>
          <div className="w-full">
            <Typography variant={'headerMedium'} className="mb-2">
              Personal Information
            </Typography>
            <div className="flex w-full flex-col gap-4">
              <TextField value={incomeFormated} onChange={() => {}} label="Total Annual Income" disabled readOnly />

              <TextField value={dobFormatted} onChange={() => {}} label="Date of Birth" disabled readOnly />

              {editableSSN ? (
                <SSN
                  label="SSN/ITIN"
                  onChange={value => {
                    setValue('ssn1', value);
                  }}
                  required={true}
                  defaultValue={formValues.ssn1}
                  error={!!errors.ssn1}
                  errorText={errors.ssn1?.message}
                />
              ) : (
                <SSN
                  label="SSN/ITIN"
                  onChange={value => {
                    setValue('ssnOrTid', value);
                  }}
                  defaultValue={'12345' + prefilledValues.ssn}
                  disabled
                  readOnly
                  prefilled={'***-**-' + prefilledValues.ssn}
                  error={!!errors.ssnOrTid}
                  errorText={errors.ssnOrTid?.message}
                />
              )}
              <SSN
                label="Confirm SSN/ITIN"
                onChange={value => setValue('ssn2', value)}
                defaultValue={formValues.ssn2}
                required={true}
                error={!!errors.ssn2}
                errorText={errors.ssn2?.message}
              />
            </div>
          </div>

          <ButtonContainer
            rightButton={
              <Button text={'NEXT STEP'} type={'submit'} onClick={handleSubmit(onFormSubmit)} buttonType={'primary'} />
            }
          />
        </form>
      </div>
    </div>
  );
};

export default PrefilledPersonalData;
