import type { Address } from '@pesto/hubble-shared/__generated__/graphql/api';
import { cn } from '@pesto/hubble-shared/utils/cn';
import { HomeIcon, MapPinHouse } from 'lucide-react';
import type { FC } from 'react';

import { SingleAddress } from './SingleAddress';

interface FormattedAddressProps {
  billingAddress?: Address | null;
}

const formatString = (str: string | null | undefined) => {
  if (!str) return '';
  return str;
};

export const formatAddress = (address: Address | null | undefined): string => {
  if (!address) return '';

  const { addressFirstLine, suiteNumber, addressSecondLine, city, state, zip } = address;

  return [
    formatString(addressFirstLine),
    formatString(suiteNumber),
    formatString(addressSecondLine),
    formatString(city),
    formatString(state),
    formatString(zip),
  ]
    .filter(Boolean)
    .join(', ');
};

export const FormattedAddress: FC<FormattedAddressProps> = ({ billingAddress }) => {
  const addresses = [
    {
      addressName: 'HELOC Address',
      addressString: formatAddress(billingAddress),
      icon: <MapPinHouse />,
    },
    {
      addressName: 'Billing Address',
      addressString: formatAddress(billingAddress),
      icon: <HomeIcon />,
    },
  ];

  return (
    <div className={cn('flex w-full gap-4')}>
      {addresses.map(address => (
        <SingleAddress key={address.addressName} {...address} />
      ))}
    </div>
  );
};
