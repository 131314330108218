import {
  CreditApplicationStatus,
  CurrentUserDocument,
  useAcceptCreditOfferMutation,
  useRejectCreditOfferMutation,
} from '@pesto/hubble-shared/__generated__/graphql/api';
import { useState } from 'react';

import { useGoRoutes } from '../../../../hooks/useGoRoutes';
import { useUser } from '../../../../hooks/useUser';

export interface FinalAgreementDataInterface {
  income: number;
  ssn: string;
  dob: string;
}

const defaultFinalAgreementData: FinalAgreementDataInterface = {
  income: 0,
  ssn: '',
  dob: '',
};

export const useOffer = () => {
  const { gotoStatus, goBack, gotoCancelOffer, gotoFinalAgreements, gotoOffer } = useGoRoutes();

  const [finalAgreementData, setFinalAgreementData] = useState<FinalAgreementDataInterface>(defaultFinalAgreementData);
  const [creditOfferMutationError, setCreditOfferMutationError] = useState<boolean>(false);
  const [modalError, setModalError] = useState<boolean>(false);
  const { user } = useUser();

  const [rejectCreditOfferMutation] = useRejectCreditOfferMutation({
    variables: {
      creditApplicationId: user?.creditApplication?.id || '',
    },
  });

  const [acceptCreditOfferMutation, { loading }] = useAcceptCreditOfferMutation({
    variables: {
      creditApplicationId: user?.creditApplication?.id || '',
      ssnItin: finalAgreementData?.ssn || '',
      dob: user?.dob || '',
      annualIncome: parseInt(user?.annualIncome || '0'),
    },
    refetchQueries: [{ query: CurrentUserDocument }],
    context: { containsSensitiveData: true },
  });

  const completeCreditApplication = () => {
    acceptCreditOfferMutation()
      .then(response => {
        if (response.errors) {
          if (response.errors[0].message === 'SSN_OR_DOB_MISMATCH') {
            const ssnNotMatched = () => {
              console.error('SSN or DOB mismatch on accepting offer:', response.errors);
              setModalError(true);
            };
            return ssnNotMatched();
          }
          return console.error('Error accepting offer:', response.errors);
        } else {
          if (response.data?.acceptCreditOffer?.status === CreditApplicationStatus.OfferDecisionNeeded) {
            console.error(
              'Error Strange Response Accepting Offer UserId:' +
                user?.id +
                ' Status:' +
                response.data?.acceptCreditOffer?.status,
            );
          }
          if (response.data?.acceptCreditOffer?.status) {
            setModalError(false);
            gotoStatus(response.data?.acceptCreditOffer?.status);
          }
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const rejectOfferHandler = () => {
    gotoCancelOffer();
  };

  const goBackHandler = () => {
    goBack();
  };

  const handleSubmitSSN = (ssn: string) => {
    setFinalAgreementData({ ...finalAgreementData, ssn });
    gotoOffer();
  };

  const handleOffer = (accepted: boolean) => {
    if (accepted) {
      gotoFinalAgreements();
    } else {
      rejectCreditOfferMutation()
        .then(response => {
          if (response.errors) {
            console.error('Error rejecting offer', response.errors);
          }

          response.data?.rejectCreditOffer?.status && gotoStatus(response.data?.rejectCreditOffer?.status);
        })
        .catch(error => {
          console.error('Error rejecting offer', error);
        });
    }
  };

  return {
    handleOffer,
    rejectOfferHandler,
    goBackHandler,
    verifyIncome: {
      finalAgreementData,
      setFinalAgreementData,
      creditOfferMutationError,
      setCreditOfferMutationError,
      handleSubmitSSN,
    },
    agreements: {
      modalError,
      setModalError,
      completeCreditApplication,
      loading,
    },
  };
};
